* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

.container {
  width:90%;
  margin: 0 auto;
}
.header {
  background-color:'#0000ff';

}
.home {
  display: flex;
  flex-direction: row;
}
.logo img{
  width: 40px;
  height: 40px;
}
a {
  text-decoration: none;
  color: black;
}
.input {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  width: 500px;
}
.input-group{
  position: relative;
}
.btnMargin{
  margin-right: 5px;
}
.input svg{
  position:absolute;
  top: 10px;
  left: 2px;
  z-index: 999;
}
.form-control{
  padding-left: 20px;
}
.footer {
  background-color: #0000ff; 
  color: white; 
  position: fixed; 
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  font-size: 0.8rem;
  padding: 4px;
  z-index: 333;
}

.show {
  position:absolute;
  top:80px;
  right: 100px;
  z-index: 999;
}
.modal-content {
  width: 70% !important;
  margin: 0 auto !important;
}
.code {
  font-size: 16px;
  border: 1px solid black;
  padding: 5px;
  width: 80%;
  margin: 10px auto;
}
image{
  align-items: center;
  margin: 5vw;
}
.image img{
  max-width: 250px;
  
}
.code-svg {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
}
.back svg {
  color:blue;
  width:2rem ;
  height: 2rem ;
  font-weight: bold;
  background-color: transparent;
}
.down svg {
  width:2rem ;
  height: 2rem ;
  background-color: transparent !important;
  color: green !important;
}
.delete svg {
  width:2rem ;
  height: 2rem ;
  background-color: transparent !important;
  color: red !important;
}
.fetchAll {
  margin: 50px auto;
  display: flex;
 flex-wrap: wrap; 
 justify-content: space-evenly;
}
.scan {
  position: relative;
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
}
.phone svg{
  width:2rem ;
  height: 2rem ;
  font-weight: bold;
  color: blue;
}
.pin svg{
  position: absolute;
  bottom: 16px;
  left: 23px;
  width:1rem ;
  height: 1rem ;
  font-weight: bold;
  color: red;
  z-index: 7;
}

.error {
  border: 2px solid #FF6565;
}
.error-message {
  color: #FF6565;
  padding: .5em .2em;
  height: 1em;
  position: absolute;
  font-size: .8em;
}
.confirmDelete {
  background-color: rgb(147, 219, 147);
  font-size: 1.2rem;
}
.center {
  display: flex;
  justify-content: center;
}
.message {
  text-align: center;
  border: 1px solid #0000ff;
  width:50vh;
  padding: 10px;
  border-radius: 10px;
  background-color: #dddde4;
}
@media screen and (max-width: 600px) {
  .container {
    width: 100% !important;
  }
}